import { Observer } from 'mobx-react-lite';
import React from 'react';
import './LoadingIndicator.scss';

type LoadingIndicatorProps = {}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = props => {
  return <Observer children={() => (
    <b className="loading-indicator"></b>
  )} />
}

export default LoadingIndicator;
import React from "react"
import PageTemplate from "../components/PageTemplate/PageTemplate"
import { PageComponent } from "../types/gatsby.types"
import joinClassNames from "../utils/className.utils"
import APP_CONTENT_CONFIG from "../constants/appContentConfig.constants"
import BackToHomepageButton from "../components/WithQuery/BackToHomepageButton/BackToHomepageButton"
import LoadingBlock from "../components/LoadingBlock/LoadingBlock"
import { Observer } from "mobx-react-lite"
import './404.scss';

type PageNotFoundProps = {}

const PageNotFound: PageComponent<PageNotFoundProps> = props => {
  const page = {
    slug: '404-not-found',
    title: 'Page not found',
    isFrontPage: false,
  }
  const { error } = APP_CONTENT_CONFIG.page.notFound;
  const s = {
    ready: true,
  }
  // const productsQueried = useAllWpProduct();;

  // const s = useStore(() => ({
  //   ready: false as boolean,
  //   get products() { return productsQueried as Product[]; },
  //   get pathname() { return window.location.pathname; },
  //   setReady: action(() => {
  //     s.ready = true;
  //   })
  // }))
  // useOnMount(() => {
  //   const ifProductPathRedirectToProductPage = () => {
  //     const product = s.products.find(p => s.pathname.includes(p.slug));
  //     if (product) {
  //       window.location.href = product.uri;
  //     } else {
  //       s.setReady();
  //     }
  //   }
  //   ifProductPathRedirectToProductPage();
  // })

  return <Observer children={() => (
    <PageTemplate
      {...props} // always include
      className={joinClassNames(page.slug)}
      pageSlug={page.slug}
      title={page.title}
      isFrontPage={page.isFrontPage}
    >

      {s.ready ? <section className="page-section section-404 natural-height-upto-desktop" data-section="home-hero">
        <div className="container">

          <h1>{error.code}</h1>

          <h2>{error.shortname}</h2>
          <p>{error.description}</p>

          <BackToHomepageButton />

        </div>
      </section> : <LoadingBlock />}


    </PageTemplate>
  )} />
}

export default PageNotFound;

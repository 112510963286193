import { Observer } from 'mobx-react-lite';
import React from 'react';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import './LoadingBlock.scss';

type LoadingBlockProps = {}

const LoadingBlock: React.FC<LoadingBlockProps> = props => {
  return <Observer children={() => (
    <div className="loading-banner">
      <div className="loading-banner-content">
        <LoadingIndicator />
        <span className="loading-banner-slot"><slot v-if="this.$slots.default"></slot></span>
      </div>
    </div>
  )} />
}

export default LoadingBlock;